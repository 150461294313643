import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

export const AppHelmet = () => {
  const location = useLocation();
  const [canonicalUrl, setCanonicalUrl] = useState("");

  useEffect(() => {
    const url = `${window.location.origin}${location.pathname}`;
    console.log("Setting canonical to:", url);
    setCanonicalUrl(url);
  }, [location]);

  return (
    <Helmet>
      <title>Helmet Test Title</title>
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Helmet>
  );
};