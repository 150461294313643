import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Provider } from 'inversify-react';
import React, { FC, ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import useCaseAppStartup from 'application/modules/bookingWizard/useCases/hooks/useCaseAppStartup';
import InfrastructureProvider from 'infrastructure/contexts/InfrastructureProvider';

import container from './container';
import { TextCopyContextProvider } from './providers/TextCopyContextProvider';
import Router from './routes/Router';
import { ColorModeProvider } from './theme/ColorModeProvider';

interface IAppWrapper {
  children?: ReactNode;
}

const AppWrapper: FC<IAppWrapper> = ({ children }) => {
  const { result, error } = useCaseAppStartup();
  if (!result && error) {
    console.error('Error while initializing app', error);
  }
  return <>{children}</>;
};
export default function App() {
  return (
    <HelmetProvider>
      <Provider container={container}>
        <InfrastructureProvider>
          <AppWrapper>
            <ColorModeProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TextCopyContextProvider>
                  <Router />
                </TextCopyContextProvider>
              </LocalizationProvider>
            </ColorModeProvider>
          </AppWrapper>
        </InfrastructureProvider>
      </Provider>
    </HelmetProvider>
  );
}
