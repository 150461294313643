import { Stack, Typography } from '@mui/material';
import React, { CSSProperties, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import GoogleStoreIcon from 'infrastructure/targets/web/assets/icons/google-store.svg';

const timeout = 3_000; // in milliseconds
async function handleDeepLink(deeplink: string, storeUrl: string) {
  const startTime = Date.now();
  window.location.href = deeplink;

  const interval = setInterval(() => {
    if (Date.now() - startTime > timeout) {
      clearInterval(interval);
      window.location.href = storeUrl;
    }

    if (document.hidden) {
      clearInterval(interval);
    }
  }, 200);
}

const StoreURL: Record<'ios' | 'android', string> = {
  ios: 'https://apps.apple.com/us/app/five-iron-golf/id6470542123',
  android: 'https://play.google.com/store/apps/details?id=com.fiveirongolf.mobileapp&hl=en_US',
};

const styles = {
  width: '246px',
  height: '82px',
  verticalAlign: 'middle',
  objectFit: 'contain',
} satisfies CSSProperties;

export const MobileDeeplink = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const deeplink = searchParams.get('deeplink');
    const platform = searchParams.get('platform') as 'ios' | 'android';

    if (!deeplink || !platform || !StoreURL[platform]) {
      return;
    }

    handleDeepLink(deeplink, StoreURL[platform]);
  }, [searchParams]);

  return (
    <Stack
      gap={6}
      padding={4}
      alignItems="center"
      direction={'column'}
      sx={{ backgroundColor: 'black', height: '100dvh' }}>
      <Typography variant="h2" color="white" width={'100%'}>
        Five Iron Golf
      </Typography>

      <a href={StoreURL.ios} style={{ display: 'block' }}>
        <img
          src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1702252800"
          alt="Download on the App Store"
          style={styles}
        />
      </a>

      <a href={StoreURL.android} style={{ display: 'block' }}>
        <img
          src={GoogleStoreIcon}
          alt="Get it on Google Play"
          style={{
            ...styles,
            border: '2px solid #a6a6a6',
            borderRadius: '12px',
          }}
        />
      </a>
    </Stack>
  );
};
