import { createSelector } from '@reduxjs/toolkit';
import { AppointmentTypes, AvailabilityTypes, CourseTypes, LocationTypes } from 'common';

import { RootState } from 'infrastructure/redux/store';

import { BookingForm } from './bookingWizard.slice';

export const selectSelf = (storeState: RootState): RootState => storeState;

export const selectBookingForm = createSelector(
  selectSelf,
  (state: RootState): BookingForm => state.bookingForm,
);

export const selectLocation = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.location,
);

export const selectCourse = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.course,
);

export const selectExperienceType = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.experienceType,
);

export const selectReservationDate = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.reservationDate,
);

export const selectPartySize = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.partySize,
);

export const selectStartTime = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.startTime,
);

export const selectEndTime = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.endTime,
);

export const selectSessionLength = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.sessionLength,
);

export const selectLessonType = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): number | undefined => bookingForm.lessonType,
);

export const selectInstructor = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.instructor,
);

export const selectEmail = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.email,
);

export const selectAppointments = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): AppointmentTypes.Appointment[] | undefined =>
    bookingForm.appointments,
);

export const selectLocationDetails = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): LocationTypes.SimulatorLocation | undefined =>
    bookingForm.locationDetails,
);

export const selectCourseDetails = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): CourseTypes.Course | undefined => bookingForm.courseDetails,
);

export const selectTeeTimeRequestDetails = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): CourseTypes.CourseRequest | undefined =>
    bookingForm.requestTeeTimeDetails,
);

export const selectResourceId = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): number | undefined => bookingForm.resourceId,
);

export const selectRedirectTo = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): string | undefined => bookingForm.redirectTo,
);

export const selectConfirmedBooking = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): AppointmentTypes.BookedAppointmentResponse[] | undefined =>
    bookingForm.confirmedBooking,
);

export const selectClubFittingType = createSelector(
  selectBookingForm,
  (bookingForm: BookingForm): AvailabilityTypes.ClubFittingType | undefined =>
    bookingForm.clubFittingType,
);

export const selectInstructorDetails = createSelector(
  selectBookingForm,
  (
    bookingForm: BookingForm,
  ): Partial<AvailabilityTypes.LessonAppointmentAvailability> | undefined =>
    bookingForm.instructorDetails,
);
